<template>
  <div>
    <div
      :class="{ 'd-none': showNot }"
      id="vue-loaderoverlay"
      style="display: block">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <nav-bar v-if="1 == 0" />
    <b-container>
      <bx-module-container>
        <!-- Startseite -->
        <div v-if="navId === undefined || navId === null">
          <b-row>
            <b-col
              v-for="kachel in kacheln"
              :key="kachel.id"
              :sm="12"
              :md="mdKachelSize(kachel.childs.length)"
              :xl="xlKachelSize(kachel.childs.length)"
              :offset-lg="kachel.offsetLg">
              <div v-if="kachel.id !== '12120B8E853'">
                <!-- Logout aus Startseite ausblenden -->
                <bx-module-categorie
                  :header="kachel.name"
                  class="mt-4 mx-3 hyphens">
                  <!-- Kachel mit 3 Modulen, gr Modul links -->
                  <b-row
                    v-if="
                      kachel.childs.length === 3 &&
                      kachel.childs[0].kachelsizeLg !== '2'
                    ">
                    <!-- Spalte 1 -->
                    <b-col class="no-gutters" :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '1'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                    <!-- Spalte 2 -->
                    <b-col
                      v-if="
                        kachel.childs.length === 3 &&
                        kachel.childs[0].kachelsizeLg !== '2'
                      "
                      class="no-gutters"
                      :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '2'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                  <!-- Kachel mit 3 Modulen, gr Modul links -->
                  <b-row
                    v-else-if="
                      kachel.childs.length === 3 &&
                      kachel.childs[2].kachelsizeLg !== '2'
                    ">
                    <!-- Spalte 1 -->
                    <b-col class="no-gutters" :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '2'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                    <!-- Spalte 2 -->
                    <b-col
                      v-if="
                        kachel.childs.length === 3 &&
                        kachel.childs[2].kachelsizeLg !== '2'
                      "
                      class="no-gutters"
                      :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '1'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                  <!-- Kachel mit Modulen gleicher Größe -->
                  <b-row v-else class="no-gutters">
                    <b-col
                      v-for="(child, index) in kachel.childs"
                      :class="child.grandChilds.length !== 0 ? 'p-1' : null"
                      :key="index"
                      :sm="12"
                      :md="
                        mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )
                      ">
                      <b-row
                        :md="6"
                        class="module-group"
                        :style="
                          child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null
                        ">
                        <!-- Module ohne Untermodule -->
                        <b-col v-if="child.grandChilds.length === 0" :sm="12">
                          <bx-module
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-col>
                        <!-- Module mit Untermodulen -->
                        <b-col v-else :md="12">
                          <div class="ml-2 pt-2 pb-1 module-title">
                            {{ child.name }}
                          </div>
                          <b-row class="no-gutters">
                            <b-col
                              v-for="(grandC, index) in child.grandChilds"
                              :key="index">
                              <bx-module
                                :href="path + grandC.path + '/'"
                                :title-html="`<div>${grandC.name}</div>`"
                                :iconClass="grandC.icon"
                                :variant="grandC.color"
                                :size="grandC.kachelsizeLg"
                                @click="showOverlay" />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </bx-module-categorie>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Ansicht bei unvollständiger URL -->
        <div v-else>
          <b-row
            v-for="kachel in kacheln"
            :key="kachel.id"
            sm="12"
            :md="mdKachelSize(kachel.childs.length)"
            :xl="xlKachelSize(kachel.childs.length)">
            <b-col>
              <div v-if="kachel.id === navId">
                <bx-module-categorie
                  :header="kachel.name"
                  class="mt-4 mx-3 hyphens">
                  <!-- Kachel mit 3 Modulen, gr Modul links -->
                  <b-row
                    v-if="
                      kachel.childs.length === 3 &&
                      kachel.childs[0].kachelsizeLg !== '2'
                    ">
                    <!-- Spalte 1 -->
                    <b-col class="no-gutters" :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '1'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                    <!-- Spalte 2 -->
                    <b-col
                      v-if="
                        kachel.childs.length === 3 &&
                        kachel.childs[0].kachelsizeLg !== '2'
                      "
                      class="no-gutters"
                      :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '2'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                  <!-- Kachel mit 3 Modulen, gr Modul links -->
                  <b-row
                    v-else-if="
                      kachel.childs.length === 3 &&
                      kachel.childs[2].kachelsizeLg !== '2'
                    ">
                    <!-- Spalte 1 -->
                    <b-col class="no-gutters" :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '2'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                    <!-- Spalte 2 -->
                    <b-col
                      v-if="
                        kachel.childs.length === 3 &&
                        kachel.childs[2].kachelsizeLg !== '2'
                      "
                      class="no-gutters"
                      :md="6">
                      <b-col
                        v-for="(child, index) in kachel.childs"
                        :key="index"
                        :sm="12"
                        :md="
                          mdModulWidth(
                            kachel.childs.length,
                            child.grandChilds.length
                          )
                        ">
                        <b-row
                          :md="6"
                          class="module-group"
                          :style="
                            child.grandChilds.length > 0
                              ? { backgroundColor: child.frameColor }
                              : null
                          ">
                          <bx-module
                            v-if="child.kachelsizeLg === '1'"
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                  <!-- Kachel mit Modulen gleicher Größe -->
                  <b-row v-else class="no-gutters">
                    <b-col
                      v-for="(child, index) in kachel.childs"
                      :class="child.grandChilds.length !== 0 ? 'p-1' : null"
                      :key="index"
                      :sm="12"
                      :md="
                        mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )
                      ">
                      <b-row
                        :md="6"
                        class="module-group"
                        :style="
                          child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null
                        ">
                        <!-- Module ohne Untermodule -->
                        <b-col v-if="child.grandChilds.length === 0" :sm="12">
                          <bx-module
                            :href="path + child.path + '/'"
                            :title="child.title || child.name"
                            :iconClass="child.icon"
                            :variant="child.color"
                            :size="child.kachelsizeLg"
                            left-aligned
                            @click="showOverlay" />
                        </b-col>
                        <!-- Module mit Untermodulen -->
                        <b-col v-else :md="12">
                          <div class="ml-2 pt-2 pb-1 module-title">
                            {{ child.name }}
                          </div>
                          <b-row class="no-gutters">
                            <b-col
                              v-for="(grandC, index) in child.grandChilds"
                              :key="index">
                              <bx-module
                                :href="path + grandC.path + '/'"
                                :title-html="`<div>${grandC.name}</div>`"
                                :iconClass="grandC.icon"
                                :variant="grandC.color"
                                :size="grandC.kachelsizeLg"
                                @click="showOverlay" />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </bx-module-categorie>
              </div>
            </b-col>
          </b-row>
        </div>
      </bx-module-container>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@c/Navbar'

import apiIntranet from '@a-i/'

export default {
  name: 'Home',
  components: { NavBar },
  data() {
    return {
      navId: window.currentNavId,
      path: 'https://intranet.regiobus.com/',
      kacheln: [],
      showNot: true,
    }
  },
  mounted() {
    this.loadKachel()
  },
  computed: {},
  methods: {
    async loadKachel() {
      try {
        this.showNot = false
        const res = await apiIntranet.v1.kacheln.get()

        this.kacheln = res.data.menu
        this.showNot = true
      } catch (e) {
        console.error(e)
        // } finally {
        //   console.log(this.kacheln)
      }
    },

    mdKachelSize(kachelCount) {
      return kachelCount === 1
        ? 12
        : kachelCount === 2
        ? 12
        : kachelCount >= 3
        ? 12
        : 12
    },
    xlKachelSize(kachelCount) {
      return kachelCount === 1
        ? 6
        : kachelCount === 2
        ? 6
        : kachelCount === 3
        ? 6
        : kachelCount === 4
        ? 6
        : 12
    },
    mdModulWidth(childLength, grandCLength) {
      return childLength === 1
        ? 12
        : grandCLength > 0
        ? 12
        : childLength === 3
        ? 12
        : 6
    },

    redirectPath(path) {
      window.location.href = path
      //this.$router.push(`${path}+${subSite}`)
    },

    showOverlay() {
      this.showNot = false
    },
  },
}
</script>

<style>
.hyphens {
  hyphens: auto;
}

.module-title {
  font-family: khand, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
}
</style>
