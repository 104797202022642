var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{ 'd-none': _vm.showNot },staticStyle:{"display":"block"},attrs:{"id":"vue-loaderoverlay"}},[_c('i',{staticClass:"fas fa-circle-notch fa-spin"})]),(1 == 0)?_c('nav-bar'):_vm._e(),_c('b-container',[_c('bx-module-container',[(_vm.navId === undefined || _vm.navId === null)?_c('div',[_c('b-row',_vm._l((_vm.kacheln),function(kachel){return _c('b-col',{key:kachel.id,attrs:{"sm":12,"md":_vm.mdKachelSize(kachel.childs.length),"xl":_vm.xlKachelSize(kachel.childs.length),"offset-lg":kachel.offsetLg}},[(kachel.id !== '12120B8E853')?_c('div',[_c('bx-module-categorie',{staticClass:"mt-4 mx-3 hyphens",attrs:{"header":kachel.name}},[(
                    kachel.childs.length === 3 &&
                    kachel.childs[0].kachelsizeLg !== '2'
                  )?_c('b-row',[_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '1')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1),(
                      kachel.childs.length === 3 &&
                      kachel.childs[0].kachelsizeLg !== '2'
                    )?_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '2')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1):_vm._e()],1):(
                    kachel.childs.length === 3 &&
                    kachel.childs[2].kachelsizeLg !== '2'
                  )?_c('b-row',[_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '2')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1),(
                      kachel.childs.length === 3 &&
                      kachel.childs[2].kachelsizeLg !== '2'
                    )?_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '1')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1):_vm._e()],1):_c('b-row',{staticClass:"no-gutters"},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,class:child.grandChilds.length !== 0 ? 'p-1' : null,attrs:{"sm":12,"md":_vm.mdModulWidth(
                        kachel.childs.length,
                        child.grandChilds.length
                      )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                          ? { backgroundColor: child.frameColor }
                          : null),attrs:{"md":6}},[(child.grandChilds.length === 0)?_c('b-col',{attrs:{"sm":12}},[_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}})],1):_c('b-col',{attrs:{"md":12}},[_c('div',{staticClass:"ml-2 pt-2 pb-1 module-title"},[_vm._v(" "+_vm._s(child.name)+" ")]),_c('b-row',{staticClass:"no-gutters"},_vm._l((child.grandChilds),function(grandC,index){return _c('b-col',{key:index},[_c('bx-module',{attrs:{"href":_vm.path + grandC.path + '/',"title-html":("<div>" + (grandC.name) + "</div>"),"iconClass":grandC.icon,"variant":grandC.color,"size":grandC.kachelsizeLg},on:{"click":_vm.showOverlay}})],1)}),1)],1)],1)],1)}),1)],1)],1):_vm._e()])}),1)],1):_c('div',_vm._l((_vm.kacheln),function(kachel){return _c('b-row',{key:kachel.id,attrs:{"sm":"12","md":_vm.mdKachelSize(kachel.childs.length),"xl":_vm.xlKachelSize(kachel.childs.length)}},[_c('b-col',[(kachel.id === _vm.navId)?_c('div',[_c('bx-module-categorie',{staticClass:"mt-4 mx-3 hyphens",attrs:{"header":kachel.name}},[(
                    kachel.childs.length === 3 &&
                    kachel.childs[0].kachelsizeLg !== '2'
                  )?_c('b-row',[_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '1')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1),(
                      kachel.childs.length === 3 &&
                      kachel.childs[0].kachelsizeLg !== '2'
                    )?_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '2')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1):_vm._e()],1):(
                    kachel.childs.length === 3 &&
                    kachel.childs[2].kachelsizeLg !== '2'
                  )?_c('b-row',[_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '2')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1),(
                      kachel.childs.length === 3 &&
                      kachel.childs[2].kachelsizeLg !== '2'
                    )?_c('b-col',{staticClass:"no-gutters",attrs:{"md":6}},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,attrs:{"sm":12,"md":_vm.mdModulWidth(
                          kachel.childs.length,
                          child.grandChilds.length
                        )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                            ? { backgroundColor: child.frameColor }
                            : null),attrs:{"md":6}},[(child.kachelsizeLg === '1')?_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}}):_vm._e()],1)],1)}),1):_vm._e()],1):_c('b-row',{staticClass:"no-gutters"},_vm._l((kachel.childs),function(child,index){return _c('b-col',{key:index,class:child.grandChilds.length !== 0 ? 'p-1' : null,attrs:{"sm":12,"md":_vm.mdModulWidth(
                        kachel.childs.length,
                        child.grandChilds.length
                      )}},[_c('b-row',{staticClass:"module-group",style:(child.grandChilds.length > 0
                          ? { backgroundColor: child.frameColor }
                          : null),attrs:{"md":6}},[(child.grandChilds.length === 0)?_c('b-col',{attrs:{"sm":12}},[_c('bx-module',{attrs:{"href":_vm.path + child.path + '/',"title":child.title || child.name,"iconClass":child.icon,"variant":child.color,"size":child.kachelsizeLg,"left-aligned":""},on:{"click":_vm.showOverlay}})],1):_c('b-col',{attrs:{"md":12}},[_c('div',{staticClass:"ml-2 pt-2 pb-1 module-title"},[_vm._v(" "+_vm._s(child.name)+" ")]),_c('b-row',{staticClass:"no-gutters"},_vm._l((child.grandChilds),function(grandC,index){return _c('b-col',{key:index},[_c('bx-module',{attrs:{"href":_vm.path + grandC.path + '/',"title-html":("<div>" + (grandC.name) + "</div>"),"iconClass":grandC.icon,"variant":grandC.color,"size":grandC.kachelsizeLg},on:{"click":_vm.showOverlay}})],1)}),1)],1)],1)],1)}),1)],1)],1):_vm._e()])],1)}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }